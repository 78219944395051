/* eslint-disable sort-keys */
import {
  useMemo,
} from 'react';
import { Props } from 'react-modal';
import { useTheme } from 'styled-components';
import merge from 'lodash.merge';

import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import { Nullable } from '@/core/interfaces/common';

import { DesktopModal } from './DesktopModal/DesktopModal';
import { MobileModal } from './MobileModal';

export type ModalProps = Props & {
  isOpen: boolean;
  onRequestClose: () => void;
  title: string;
  image: string;
  alt?: Nullable<string>;
  content: string;
};

export const Modal = ({
  ...props
}: ModalProps) => {
  const { isTabletOrAbove } = useGlobalContext();

  const {
    colors,
  } = useTheme();

  const modalStyles = useMemo<Props['style']>(() => {
    const standardCustomStyles: Props['style'] = {
      overlay: {
        background: colors.alpha.black24,
        backdropFilter: 'blur(1.5px)',
        zIndex: 99,
        overflow: 'hidden',
      },
      content: {
        maxWidth: '90%',
        width: '1090px',
        borderRadius: 0,
        border: 0,
        top: '50%',
        right: 'auto',
        bottom: 'auto',
        left: '50%',
        padding: 0,
        maxHeight: '90vh',
        transform: 'translate(-50%, -50%)',
      },
    };

    return merge(standardCustomStyles, props.style);
  }, [
    colors.alpha.black24,
    props.style,
  ]);

  const onAfterClose = () => {
    document.body.classList.remove('ReactModal__Body--open');
  };

  return (
    <>
      {isTabletOrAbove ?
        (
          <DesktopModal
            {...props}
            onAfterClose={onAfterClose}
            standardCustomStyles={modalStyles}
          />
        ) :
        (
          <MobileModal
            {...props}
            onAfterClose={onAfterClose}
            standardCustomStyles={modalStyles}
          />
        )}
    </>

  );
};
