import { Bar } from '@/core/components/Bar/Bar';
import { HeaderWrapper } from '@/core/components/HeaderWrapper';
import { Nullable } from '@/core/interfaces/common';

import {
  BannerWrapper,
  Content,
  Subtitle,
  Title,
} from './Banner.styles';

type BannerProps = {
  Image: string;
  TitleText: string;
  SubtitleText?: Nullable<string>;
};

export const Banner = ({
  Image,
  TitleText,
  SubtitleText = '',
}: BannerProps) => {
  const isPatternImage = Image === '/assets/images/vectorBackgrounds/grey.svg';

  return (
    <HeaderWrapper>
      <BannerWrapper image={Image}>
        <Content $hasSubtitle={!!SubtitleText}>
          <Title $reversedColor={isPatternImage}>{TitleText}</Title>
          <Bar
            fixedWidth
            height={2}
          />
          {SubtitleText && (
            <Subtitle $reversedColor={isPatternImage}>{SubtitleText}</Subtitle>
          )}
        </Content>
      </BannerWrapper>
    </HeaderWrapper>
  );
};
