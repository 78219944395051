/* eslint-disable sort-keys */
import { ComponentType } from 'react';
import ReactModal from 'react-modal';

import { ButtonVariant } from '../../Button/Button.types';
import { IconButton } from '../../IconButton';
import { IconButtonSizes } from '../../IconButton/IconButton.types';
import { Close } from '../../Icons';
import {
  CloseContainer,
  ContentContainer,
  ContentSection,
  ModalImage,
  ModalText,
  ModalTopContainer,
  TitleContainer,
} from './MobileModal.styles';
import { ModalProps } from '../Modal';

export const MobileModal = ({
  standardCustomStyles,
  isOpen,
  style,
  title,
  content,
  onRequestClose,
  image,
  alt,
  onAfterClose,
  ...props
}: ModalProps & { standardCustomStyles: ReactModal.Styles | undefined }) => {
  const Modal = ReactModal as ComponentType<ReactModal['props']>;

  return (
    <Modal
      onAfterClose={onAfterClose}
      isOpen={isOpen}
      style={standardCustomStyles}
      onRequestClose={onRequestClose}
      {...props}
    >
      <ModalTopContainer>
        <CloseContainer>
          <IconButton
            variant={ButtonVariant.GHOST_INVERTED}
            size={IconButtonSizes.REGULAR}
            icon={<Close />}
            onClick={onRequestClose}
            ariaLabel="close modal"
          />
        </CloseContainer>
        <div>
          <ModalImage
            alt={alt || `${title} Main Image`}
            src={image}
          />
        </div>
        <ContentSection>
          <ContentContainer>
            <TitleContainer>{title}</TitleContainer>
            <ModalText>{content}</ModalText>
          </ContentContainer>
        </ContentSection>
      </ModalTopContainer>
    </Modal>
  );
};
