/* eslint-disable sort-keys */
import {
  ComponentType,
} from 'react';
import ReactModal from 'react-modal';

import {
  CloseContainer,
  ContentContainer,
  ContentSection,
  ImageContainer,
  ModalImage,
  ModalText,
  ModalTopContainer,
  TitleContainer,
} from './DesktopModal.styles';
import { ButtonVariant } from '../../Button/Button.types';
import { IconButton } from '../../IconButton';
import { IconButtonSizes } from '../../IconButton/IconButton.types';
import { Close } from '../../Icons';
import { ModalProps } from '../Modal';

export const DesktopModal = ({
  standardCustomStyles,
  isOpen,
  style,
  title,
  content,
  children,
  onRequestClose,
  image,
  alt,
  onAfterClose,
  ...props
}: ModalProps & { standardCustomStyles: ReactModal.Styles | undefined }) => {
  const Modal = ReactModal as ComponentType<ReactModal['props']>;

  return (
    <>
      <Modal
        onAfterClose={onAfterClose}
        isOpen={isOpen}
        style={standardCustomStyles}
        onRequestClose={onRequestClose}
        {...props}
      >
        <ModalTopContainer>
          <ImageContainer>
            {image && (
              <ModalImage
                alt={alt || `${title} Main Image`}
                src={image}
              />
            ) }
          </ImageContainer>
          <ContentSection>
            <CloseContainer>
              <IconButton
                variant={ButtonVariant.SECONDARY}
                size={IconButtonSizes.REGULAR}
                icon={<Close />}
                onClick={onRequestClose}
                ariaLabel="close modal"
              />
            </CloseContainer>
            <ContentContainer>
              <TitleContainer>{title}</TitleContainer>
              <ModalText>{content}</ModalText>
            </ContentContainer>
          </ContentSection>
        </ModalTopContainer>
      </Modal>
    </>
  );
};
