import styled from 'styled-components';

import { H4 } from '@/core/components/Typography/Typography.styles';

export const ModalTopContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
`;

export const TitleContainer = styled.h4`
  ${H4};
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 28px;
  font-family: var(--executive-website-heading-font-family);
  line-height: 32px;
  letter-spacing: 0.3px;
`;

export const ModalText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-style: normal;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 16px;
  font-family: var(--executive-website-regular-font-family);
  line-height: 24px;
  letter-spacing: 0.3px;
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.grey};
`;

export const ModalImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  gap: 24px;
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;
