import {
  ComponentPropsWithRef,
  ElementType,
} from 'react';

export type Nullable<T = undefined> = T | null;

export type MergeElementProps<
  T extends ElementType,
  // Check this comment: https://github.com/microsoft/TypeScript/issues/21732#issuecomment-886221640
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends object = {}
> = Omit<ComponentPropsWithRef<T>, keyof P> & P;

export type PageData = {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
};

export type Banner = {
  id: number;
  header: string;
  paragraph: string;
  banner: Image;
  backgroundColor?: Color;
};

export type PageHeader = {
  subtitle: string;
  title: string;
};

export type DataType<T> = {
  id: number;
  attributes: T;
}

export type FormatAttributes = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
};

export type Formats = {
  large?: FormatAttributes;
  medium?: FormatAttributes;
  small?: FormatAttributes;
  thumbnail: FormatAttributes;
};

export type ImageAttributes = {
  name: string;
  alternativeText: Nullable<string>;
  caption: Nullable<string>;
  width: number;
  height: number;
  formats: Nullable<Formats>;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  providerMetadata: null;
  createdAt: Date;
  updatedAt: Date;
};

export type Image = {
  data: Nullable<DataType<ImageAttributes>>;
};

export type Relation<T> = {
  data: T;
};

export enum Color {
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  GREY = 'grey',
  DARK_GREY = 'darkGrey',
}

export type Keyword = {
  id: number;
  keyword: string;
}

export type Meta = {
  id: number;
  keywords: Array<Keyword>;
  description: Nullable<string>;
  OGTitle: Nullable<string>;
  OGDescription: Nullable<string>;
  title: Nullable<string>;
}

export type NavigationData = DataType<Navigation>;

export interface Navigation extends PageData {
  header: Array<NavigationItem>;
  footer: Array<NavigationItem>;
}

export interface NavigationItem {
  id: number;
  link: string;
  name: string;
}
