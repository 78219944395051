import styled from 'styled-components';

import {
  BodyMedium,
  H3,
} from '../../Typography/Typography.styles';

export const ModalTopContainer = styled.div`
  position: sticky;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
`;

export const TitleContainer = styled.h3`
  ${H3}
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-style: normal;
  font-size: 40px;
  font-family: var(--executive-website-heading-font-family);
  line-height: 48px;
`;

export const ModalText = styled.p`
  ${BodyMedium}
  margin: 0;
  font-family: var(--executive-website-regular-font-family);
`;

export const CloseContainer = styled.div`
  margin-inline-start: auto;
`;

export const ModalImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  width: 50%;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  gap: 24px;
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  padding: clamp(24px, 4%, 40px);
`;
