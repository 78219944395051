import { Color } from '@/core/interfaces/common';

import { MomentId } from '@/features/Moments/moments.types';

import { SingleMoment } from './moments.types';

type Props = {
  array: Array<SingleMoment | MomentId>;
  increments: Array<number>;
  backgroundColors: Array<Color>;
}

export const insertVariantCardsMoments = ({
  array,
  increments,
  backgroundColors,
}: Props): Array<MomentId> => {
  let emptyMomentIndex = 0;
  let incrementsIndex = 0;

  const result = array?.reduce((acc: any, item, index: number) => {
    if (index === emptyMomentIndex) {
      emptyMomentIndex += increments[incrementsIndex % increments.length];
      incrementsIndex += 1;

      return [
        ...acc,
        item,
        {
          attributes: {
            backgroundColor:
                  backgroundColors[incrementsIndex % increments.length],
          },
        },
      ];
    }

    return [
      ...acc,
      item,
    ];
  }, []) as unknown as Array<MomentId>;

  return result;
};
