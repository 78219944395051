import styled, { css } from 'styled-components';

import { Color } from '@/core/interfaces/common';

type ColorProps = Color | undefined

const vectorVariant = ({ $color }: { $color: ColorProps }) => {
  switch ($color) {
    case Color?.BLUE:
      return css`
        border: ${({ theme }) => theme.colors.decorativeBlue[400]};
        background: ${({ theme }) => theme.colors.decorativeBlue[200]};

        path {
          fill: ${({ theme }) => theme.colors.decorativeBlue[400]};
        }
      `;
    case Color?.GREEN:
      return css`
        border: ${({ theme }) => theme.colors.green[400]};
        background: ${({ theme }) => theme.colors.green[100]};

        path {
          fill: ${({ theme }) => theme.colors.green[400]};
        }
      `;
    case Color?.YELLOW:
    default:
      return css`
        border: ${({ theme }) => theme.colors.yellow[400]};
        background: ${({ theme }) => theme.colors.yellow[100]};

        path {
          fill: ${({ theme }) => theme.colors.yellow[400]};
        }
      `;
  }
};

export const Wrapper = styled.div<{ $color: ColorProps }>`
  height: max-content;
  ${vectorVariant}
`;
