import styled from 'styled-components';

import { H1 } from '@/core/components/Typography';

import { breakpoint } from '@/themes/breakpoints';

export const BannerWrapper = styled.div< { image: string } >`
  display: flex;
  align-items: flex-end;
  height: 640px;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div<{ $hasSubtitle?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 16px 10px 16px;
  padding-bottom: ${({ $hasSubtitle }) => !$hasSubtitle && '56px'};

  @media ${breakpoint.sm} {
    margin: 0 32px 20px 32px;
  }

  @media ${breakpoint.md} {
    max-width: 736px;
    margin: 0 64px 30px 64px;
  }

  @media ${breakpoint.lg} {
    max-width: 828px;
    margin: 0 80px 40px 80px;
  }
`;

export const Title = styled.h1<{ $reversedColor: boolean }>`
  margin-bottom: 24px;
  color: ${({
    theme: { colors },
    $reversedColor,
  }) => ($reversedColor ? colors.black : colors.white)};
  ${H1}
`;

export const Subtitle = styled.p<{ $reversedColor: boolean }>`
  margin-top: 24px;
  color: ${({
    theme: { colors },
    $reversedColor,
  }) => ($reversedColor ? colors.neutral[700] : colors.neutral[100])};
  font-size: 16px;
  line-height: 150%;

  @media ${breakpoint.md} {
    font-size: 18px;
    line-height: 177.778%;
  }
`;
